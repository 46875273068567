	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/01/2019 13:45
  	*/


@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/font/Flaticon.eot");
  src: url("../fonts/flaticon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/font/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/font/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-3d-design:before { content: "\f100"; }
.flaticon-web-programming:before { content: "\f101"; }
.flaticon-app-development:before { content: "\f102"; }
.flaticon-advertising:before { content: "\f103"; }
.flaticon-branding:before { content: "\f104"; }
.flaticon-vector:before { content: "\f105"; }
.flaticon-computer:before { content: "\f106"; }
.flaticon-zoom:before { content: "\f107"; }
.flaticon-suitcase:before { content: "\f108"; }
.flaticon-loyalty:before { content: "\f109"; }
.flaticon-coffee:before { content: "\f10a"; }
.flaticon-calendar:before { content: "\f10b"; }
.flaticon-listening:before { content: "\f10c"; }
.flaticon-suitcases:before { content: "\f10d"; }
.flaticon-video-player:before { content: "\f10e"; }
.flaticon-football:before { content: "\f10f"; }